<template>
  <div>
    <slot>
      <div class="field grid">
        <label class="col-12 mb-2 md:col-2 md:mb-0">{{field.label || field.name}}</label>
        <div class="col-12 mb-2 md:col-10 md:mb-0">
          <Calendar ref="calendar" v-if="field.dataType=='datetime' || field.dataType=='date'"
            :value="innerValue"
            :showIcon="true"
            :showTime="field.dataType=='datetime'"
            :showSeconds="true"
            :manualInput="true"
            @update:modelValue="updateModelValue"/>
          <InputNumber v-else-if="field.dataType=='numeric'" :value="innerValue" @input="handleInnerInput"/>
          <Checkbox v-else-if="field.dataType=='boolean'" :value="innerValue" :binary="true"  @input="handleInnerInput" />
          <InputText v-else-if="field.dataType=='text'" :value="innerValue" @input="handleInnerInput"/>
          <AutoCompleteValue v-else-if="field.dataType=='options'" :options="options" :value="innerValue" @input="handleInnerInput"/>
        </div>
      </div>
    </slot>
    <slot name="message">
      <small class="p-error" v-if="message">{{message}}</small>
    </slot>
  </div>
</template>
<script>
import AutoCompleteValue from './AutoCompleteValue';
export default {
  name:"form-field",
  components:{AutoCompleteValue},
  props: {
    field: {
      type: Object,
      default() {
        return {}
      }
    },
    value: {
      type: [String,Number,Boolean,Array,Object,Date],
      default() {
        return null;
      }
    },
    invalid: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      options: [],
      innerValue:this._convert(this.value)
    }
  },
  mounted() {
    this.innerValue = this._convert(this.value);
  },

  updated() {
    this.innerValue = this._convert(this.value);
    //console.log(this.message);
  },
  computed: {

  },
  methods: {
    _convert(input) {
      if(input != null && input != undefined) {
        switch(this.field.dataType) {
          case 'datetime':
            return this.$refs.calendar.formatValue(input);
          default:
            return input;
        }
      }
    },
    handleInnerInput(event) {
      this.innerValue = event.target.value;
      this.$emit('update:modelValue', this.innerValue);
    },
    updateModelValue(event) {
      this.innerValue = event ? this.$refs.calendar.formatValue(event) : null;
      this.$emit('update:modelValue', event);
    }
  }
}
</script>
