<template>
  <div class="grid mt-1">
    <div id="shipment_form" class="card p-fluid p-inputtext-sm md:col-6">
      <h5 style="font-weight:bold;">{{ $t('shipment.form_create')}}</h5>
      <div class="field grid">
        <label for="departure" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('shipment.departure')}}</label>
        <FormField :message="errors.departure" class="col-12 md:col-10">
          <Calendar v-model="entity.departure" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" :required="true"/>
        </FormField>
      </div>
      <div class="field grid">
        <label for="awbCode" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('shipment.awbCode')}}</label>
        <form-field :message="errors.awbCode" class="col-12 md:col-10">
          <InputText ref="awbCode" id="awbCode" v-model.trim="entity.awbCode"  autofocus :required="false"/>
        </form-field>
      </div>
      <div class="field grid">
        <label for="weightLbs" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('shipment.weightLbs')}}</label>
        <form-field :message="errors.weightLbs" class="col-12 md:col-10">
          <InputNumber ref="weightLbs" id="weightLbs" v-model.trim="entity.weightLbs" :required="true" autofocus />
        </form-field>
      </div>
      <div class="field grid">
        <label for="note" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('shipment.note')}}</label>
        <div class="col-12 md:col-10">
          <Textarea id="note" v-model="entity.note" required="false" rows="5" cols="20" />
        </div>
      </div>
      <div class="field grid">
        <div class="col-2"/>
        <Toolbar class="col-10 my-2" style="border:0px; padding:0rem 0rem 0rem 0.5rem">
          <template #start>
            <Button :label="$t('button.save')" icon="pi pi-save" @click="onSave($event)"/>
            <i class="mr-2" />
            <!-- <Button :label="$t('button.reset')" icon="pi pi-replay" @click="onReset($event)"/>
            <i class="mr-2" /> -->
          </template>
        </Toolbar>
      </div>  
   </div> 
  </div>
</template>
<script>
//import Form from '@/components/form/Form.vue';
import FormField from '@/components/form/FormField';
import AutoCompleteValue from '@/components/form/AutoCompleteValue';
import ShipmentService from "@/service/ShipmentService";
import mixin_form from '@/mixins/form';
import adjustment from "@/utils/adjustment";
export default {
  components: {FormField, AutoCompleteValue},
  mixins:[mixin_form],
  created(){
    
  },
  data() {
    return {
      submitted: false,
      packageCodes: [],
      receiptCodes: [],
      shipmentCodes: [],
      entity: {},
      errors: {},
    }
  },
  mounted() {
   
  },
  methods: {
    onSave() {
      if(!this.submitted) {
        this.errors = this.validate(this.entity, 'shipment');
        if(Object.keys(this.errors).length == 0) {
          this.submitted = true;
          ShipmentService.create(this.entity)
          .then((response) => {
            this.entity = adjustment.adjustShipment(response);
            this.$router.push({ name: 'agent_shipment_edit', params: {id: this.entity.id} });
          })
          .catch((e) => {
            console.log(e);
          });
        }
      }
    }
  }
}
</script>
